import React from 'react';
import { urlCourseSelector } from '../Utils/jsFunctions';

export default function CircleNav(props) {
  let { switchForm, step, course, selectedProgram } = props;
  let circleClass = 'circle pointer';
  if (!course) course = urlCourseSelector(selectedProgram || {});
  return (
    <div className="circle-container">
      <span
        className={step === 'step1' ? `${circleClass} red` : `${circleClass}`}
        onClick={() => switchForm(course, 'step1')}
      ></span>
      <span
        className={step === 'step2' ? `${circleClass} red` : `${circleClass}`}
        onClick={() => switchForm(course, 'step2')}
      ></span>
      <span
        className={step === 'step3' ? `${circleClass} red` : `${circleClass}`}
        onClick={() => switchForm(course, 'step3')}
      ></span>
    </div>
  );
}
