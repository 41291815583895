import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

class CohortSummary extends Component {
  render() {
    return (
      <div
        className="form-section"
        style={{ padding: '1rem', marginTop: '1rem' }}
      >
        <h2>Selection Summary</h2>
        {this.props.selectedProgram.cohort_id ? (
          <>
            <p style={{ color: '#FF3235' }}>
              {this.props.selectedProgram.program_name}
            </p>
            <p>{this.props.selectedProgram.price}</p>
            <p>
              {this.props.selectedProgram.start_date ? (
                <>
                  {this.props.selectedProgram.start_date} -{' '}
                  {this.props.selectedProgram.end_date}
                </>
              ) : (
                'Start dates to be announced soon'
              )}
            </p>
            <p>
              {this.props.selectedProgram.locationDisplayed}
              {this.props.selectedProgram.city === 'Brooklyn Park' && ', MN'}
            </p>
            <p>
              {this.props.selectedProgram.application_deadline && (
                <>
                  Application Deadline:{' '}
                  {this.props.selectedProgram.application_deadline}
                </>
              )}
            </p>
            <Link to="/">Edit Selection</Link>
          </>
        ) : (
          <p>
            Please <Link to="/">select a program</Link> to continue with your
            application.
          </p>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedProgram: state.selectedProgram,
    cohorts: state.cohorts,
  };
}

export default connect(mapStateToProps)(CohortSummary);
