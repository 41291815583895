import {
  SET_SELECTED_PROGRAM,
  SET_COHORTS,
  UPDATE_FORM,
  SET_DISCOUNT,
} from './actionCreators';

export function setSelectedProgram(selectedProgram) {
  if (!selectedProgram.cohort_name) selectedProgram.cohort_name = '';
  return {
    type: SET_SELECTED_PROGRAM,
    payload: {
      program: {
        ...selectedProgram,
      },
      isPrepProgram:
        selectedProgram.program_identifier === 'software-engineering-prep'
          ? true
          : false,
    },
  };
}

export function setCohorts(cohorts) {
  return {
    type: SET_COHORTS,
    payload: cohorts,
  };
}

export function setDiscountCode(
  discountCode,
  validated,
  discountCodeData = {},
) {
  let programAssociationDict;
  if (discountCodeData.programAssociations) {
    programAssociationDict = discountCodeData.programAssociations.reduce(
      (a, v) => {
        a[v.Program__r.Slug__c] = v;
        return a;
      },
      {},
    );
  }

  return {
    type: SET_DISCOUNT,
    payload: {
      discountCode,
      discountCodeMeta: {
        validated,
        programAssociations: programAssociationDict || {},
        type: discountCodeData.Type__c,
        useAdvertisedRate:
          discountCodeData.To_be_discounted_off__c ===
          'Advertised Tuition Rate',
        discountPercentage:
          discountCodeData.Type__c === 'Percentage'
            ? discountCodeData.discount_percentage__c
            : null,
        discountDollarAmount:
          discountCodeData.Type__c === 'Dollar Amount'
            ? discountCodeData.Dollar_Amount__c
            : null,
      },
    },
  };
}

export function updateForm(formObject) {
  return {
    type: UPDATE_FORM,
    payload: formObject,
  };
}
