import axios from 'axios';
import { captureException } from '@sentry/browser';

function replaceWithStars(strLen) {
  let stars = '';
  for (let i = 0; i < strLen; i++) {
    stars += '*';
  }
  return stars;
}

function handleChange(val, key, optCB) {
  let obj = {};
  let split = key.split('.');
  if (split.length > 1) {
    obj[split[0]] = Object.assign({}, this.state[split[0]]);
    obj[split[0]][split[1]] = val;
    if (obj[split[0]].hasOwnProperty('touched')) obj[split[0]].touched = true;
  } else obj[key] = val;
  if (key === 'cardNumber') {
    let stripped = val.replace(/-/g, '');
    obj[key] = stripped;
    obj.formattedCardNumber = stripped
      .replace(/\s/g, '')
      .replace(/(.{4})/g, '$1-')
      .replace(/-$/, '');
  }
  this.setState.apply(this, [obj, optCB]);
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function applyErrorClasses(classArr) {
  //This looks for the last p tag in a label and removes hidden for error handling
  classArr.forEach((refName) => {
    if (!this[refName].current) return;
    let children = this[refName].current.children;
    children[children.length - 1].classList.remove('hidden');
  });
}

function hideError(refName) {
  if (!this[refName].current) return;
  let children = this[refName].current.children;
  if (!children[children.length - 1].classList.contains('hidden'))
    children[children.length - 1].classList.add('hidden');
}

async function checkDiscountCode(code, programIdentifier) {
  return await axios.get(
    `/api/discountCode/${code}${
      programIdentifier ? `?programIdentifier=${programIdentifier}` : ''
    }`,
  );
}

function switchForm(course, step = 'step1', reset = false) {
  if (reset) this.props.setSelectedProgram({});
  this.props.updateForm(this.state);
  this.props.history.push(`/application/${step}?course=${course}`);
}

const urlCourseSelector = (cohort = {}) => {
  switch (cohort.program_identifier) {
    case 'python-101':
      return 'online-python';
    case 'software-engineering-prep':
      return 'prep'
    default:
      return 'software-engineering';
  }
};

function splitQueryString(queryString) {
  let queryPairs = queryString
    .substr(1, queryString.length)
    .split('&')
    .reduce((a, v) => {
      let split = v.split('=');
      a[split[0]] = split[1];
      return a;
    }, {});
  return queryPairs;
}

const parseUrl = function () {
  const linkObj = {
    isDirectLink: false,
    cohortName: new URLSearchParams(this.props.location.search).get(
      'cohortName',
    ),
  };
  if (this.props.location.pathname.indexOf('/application') !== -1)
    linkObj.isDirectLink = true;
  return linkObj;
};

function deleteCookie(name, path, domain) {
  const getCookie = (name) => {
    return document.cookie.split(';').some((c) => {
      return c.trim().startsWith(name + '=');
    });
  };

  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
  }
}

const setDiscountedTotal = ({
  discountType,
  discountPercentage,
  discountDollarAmount,
  useAdvertisedRate,
  currentPrice,
  standardTuition,
}) => {
  let discountedTotal;
  if (discountType === 'Dollar Amount') {
    if (!useAdvertisedRate) {
      discountedTotal = standardTuition - discountDollarAmount;
    } else discountedTotal = currentPrice - discountDollarAmount;
  } else {
    if (!useAdvertisedRate) {
      discountedTotal = (discountPercentage / 100) * standardTuition;
    } else discountedTotal = (discountPercentage / 100) * currentPrice;
  }

  if (useAdvertisedRate) {
    discountedTotal = currentPrice - discountedTotal;
    if (discountedTotal < 0) discountedTotal = 0;
  } else if (discountedTotal < currentPrice) discountedTotal = discountedTotal;
  else discountedTotal = currentPrice;
  return discountedTotal;
};

const setIsRemote = (selectedProgram = {}) =>
  !!(selectedProgram && selectedProgram.locationDisplayed === 'Remote');

const setIsDisabled = (isRemote, citizen) =>
  !!(isRemote && citizen && citizen.touched && !citizen.status);

export {
  replaceWithStars,
  handleChange,
  validateEmail,
  applyErrorClasses,
  hideError,
  checkDiscountCode,
  switchForm,
  splitQueryString,
  parseUrl,
  urlCourseSelector,
  deleteCookie,
  setDiscountedTotal,
  setIsRemote,
  setIsDisabled,
};

export const UTM_PARAMS_KEY = 'utm_params';

export const getUtmParamsFromURL = () => {
  const params = new URLSearchParams(window.location.search);

  const source = params.get('utm_source') || '';
  const medium = params.get('utm_medium') || '';
  const campaign = params.get('utm_campaign') || params.get('campaign') || '';
  const content = params.get('utm_content') || '';
  const paidVsOrganic = params.get('paid_vs_organic') || '';
  const adType = params.get('ad_type') || '';
  const organicType = params.get('organic_type') || '';

  if (
    source ||
    medium ||
    campaign ||
    content ||
    paidVsOrganic ||
    adType ||
    organicType
  ) {
    return {
      source,
      medium,
      campaign,
      content,
      paidVsOrganic,
      adType,
      organicType,
    };
  }
  return null;
};

// get UTM params from the URL or localStorage if they exist
// (these are set in localStorage in app.js)
// utm object is nullable but not the individual params
export const getUtmParams = () => {
  const paramsFromUrl = getUtmParamsFromURL();
  const paramsFromLocalStorage = getUtmParamsFromLocalStorage();

  return paramsFromUrl || paramsFromLocalStorage;
};

const getUtmParamsFromLocalStorage = () => {
  try {
    return JSON.parse(localStorage.getItem(UTM_PARAMS_KEY) || 'null');
  } catch (err) {
    captureException(err);
    return null;
  }
};

export const setUtmParamsInLocalStorage = () => {
  // capture utm params in localStorage so we dont lose them on navigation
  const params = getUtmParamsFromURL();
  if (params && typeof params === 'object') {
    try {
      localStorage.setItem(UTM_PARAMS_KEY, JSON.stringify(params));
    } catch (err) {
      captureException(err);
    }
  }
};
