import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  handleChange,
  applyErrorClasses,
  hideError,
  switchForm,
  checkDiscountCode,
  setIsRemote,
  getUtmParams,
  // setIsDisabled,
} from '../../../Utils/jsFunctions';
import { validateSelectedCohort } from '../utils/componentFunctions';
import { updateForm, setSelectedProgram } from '../../../Redux/actions';
import axios from 'axios';
import NoProgramSelectedWarning from '../../../Components/NoProgramSelectedWarning';
import CircleNav from '../../../Components/CircleNav';
import { concatErrorQueries } from '../../../Utils/errorHandling';
import ConfirmationDialog from '../../../Components/ConfirmationDialog';
// import InternationalRemoteWarning from '../../../Components/InternationalRemoteWarning';

const gtag = window.gtag;

class OnlinePython3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardNumber: '',
      nameOnCard: '',
      mm: '',
      yy: '',
      code: '',
      onlineExperience: props.onlineExperience,
      onlineConfident: props.onlineConfident,
      onlineEnsureSuccess: props.onlineEnsureSuccess,
      submitting: false,
      formattedCardNumber: '',
      cohortSelected: validateSelectedCohort.call(this),
    };

    //refs
    this.onlineExperience = React.createRef();
    this.onlineConfident = React.createRef();
    this.onlineEnsureSuccess = React.createRef();
    this.cardNumberRef = React.createRef();
    this.nameRef = React.createRef();
    this.mmRef = React.createRef();
    this.yyRef = React.createRef();
    this.codeRef = React.createRef();
    this.submitRef = React.createRef();

    //bind util functions
    this.switchForm = switchForm.bind(this);
  }

  componentDidMount() {
    let { discountCode, discountCodeMeta, selectedProgram } = this.props;
    // handle discount code if one was passed from redux
    if (discountCode && (!discountCodeMeta || !discountCodeMeta.validated)) {
      this.validateDiscountCode(discountCode);
    } else if (discountCodeMeta) {
      // set discount amount based on previous calculations
      this.setState({
        discountAmount: `$${
          discountCodeMeta.discountDollarAmount ||
          (discountCodeMeta.discountPercentage / 100) *
            selectedProgram.price_int
        }`,
        discountedTotal: `$${selectedProgram.discountedTotal}`,
      });
    }
  }

  handleSubmit = (e, submitVal) => {
    if (submitVal === 'cancel') return;
    if (!this.state.submitting) {
      this.setState(
        {
          submitting: true,
        },
        () => {
          let submitButton = this.submitRef.current;
          submitButton.disabled = true;
          submitButton.classList.add('disabled-button');
          if (this.validateInfo()) {
            this.formSubmit();
          } else {
            this.setState(
              {
                submitting: false,
              },
              () => {
                document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                submitButton.disabled = false;
                submitButton.classList.remove('disabled-button');
              },
            );
          }
        },
      );
    }
  };

  validateInfo = () => {
    //Required fields here are all of the credit card information, selected program
    //assume info is good
    let passedValidation = true;
    let invalidFields = [];

    let {
      cardNumber,
      nameOnCard,
      mm,
      yy,
      code,
      onlineExperience,
      onlineConfident,
      discountedTotal,
    } = this.state;

    let refs = [
      { val: onlineExperience, refName: 'onlineExperience' },
      { val: onlineConfident, refName: 'onlineConfident' },
    ];

    if (discountedTotal !== '$0') {
      refs.push(
        { val: cardNumber, refName: 'cardNumberRef' },
        { val: nameOnCard, refName: 'nameRef' },
        { val: mm, refName: 'mmRef' },
        { val: yy, refName: 'yyRef' },
        { val: code, refName: 'codeRef' },
      );
    }

    refs.forEach((refObj) => {
      if (!refObj.val) {
        invalidFields.push(refObj.refName);
        passedValidation = false;
      } else {
        hideError.call(this, refObj.refName);
      }
    });

    if (discountedTotal !== '$0' && !this.validateCreditCard(invalidFields))
      passedValidation = false;

    if (invalidFields.length) applyErrorClasses.call(this, invalidFields);

    return passedValidation;
  };

  validateCreditCard = (invalidFields) => {
    let passedValidation = true;

    let { cardNumber, mm, yy, code } = this.state;

    if (isNaN(cardNumber)) {
      if (invalidFields.indexOf('cardNumberRef') === -1)
        invalidFields.push('cardNumberRef');
      passedValidation = false;
    }

    if (isNaN(mm) || parseInt(mm) < 0 || parseInt(mm) > 12 || mm.length !== 2) {
      if (invalidFields.indexOf('mmRef') === -1) invalidFields.push('mmRef');
      passedValidation = false;
    }

    if (isNaN(yy) || parseInt(yy) < 0 || parseInt(yy) > 31 || yy.length !== 2) {
      if (invalidFields.indexOf('yyRef') === -1) invalidFields.push('yyRef');
      passedValidation = false;
    }

    if (isNaN(code) || code.length !== 3) {
      if (invalidFields.indexOf('codeRef') === -1)
        invalidFields.push('codeRef');
      passedValidation = false;
    }

    return passedValidation;
  };

  goBack = (e) => {
    e.preventDefault();
    let { history } = this.props;
    this.props.updateForm(this.state);
    history.push(`/application/step2?course=online-python`);
  };

  formSubmit = async () => {
    let {
      selectedProgram,
      first,
      last,
      email,
      birthday,
      city,
      state,
      citizen,
      country,
      ethnicity,
      identify,
      linkedin,
      phone,
      github,
      otherLinks,
      resumeFile,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      applyingSoftwareProgram,
      hearAbout,
      address,
      discountCode,
    } = this.props;

    let {
      cardNumber,
      nameOnCard,
      mm,
      yy,
      code,
      onlineExperience,
      onlineConfident,
      onlineEnsureSuccess,
    } = this.state;

    let completedForm = {
      selectedProgram,
      first,
      last,
      email,
      birthday,
      city,
      state,
      country,
      citizen,
      ethnicity,
      identify,
      linkedin,
      phone,
      github,
      otherLinks,
      resumeFile,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      applyingSoftwareProgram,
      hearAbout,
      cardNumber,
      nameOnCard,
      mm,
      yy,
      code,
      address,
      onlineExperience,
      onlineConfident,
      onlineEnsureSuccess,
      discountCode,
    };

    this.props.updateForm({
      onlineExperience,
      onlineConfident,
      onlineEnsureSuccess,
    });
    try {
      const utm = getUtmParams();
      await axios.post('/api/submitForm?type=online-python', {
        ...completedForm,
        utm,
      });
      if (gtag) {
        // Fire off event to let marketing know there was an application submission
        gtag('event', 'application_submitted', {
          event_category: 'admissions',
          event_label: selectedProgram.program_identifier,
        });
      }
      this.props.history.push(
        `/submission/success?program=${selectedProgram.program_identifier}`,
      );
    } catch (err) {
      let queryString = '';
      if (err.response && err.response.data)
        queryString = concatErrorQueries(err.response.data);
      this.props.history.push(`/submission/error${queryString}`);
    }
  };

  validateDiscountCode = async (discountCode) => {
    const { selectedProgram } = this.props;
    let response = await checkDiscountCode(
      discountCode,
      selectedProgram.program_identifier,
    );
    let { data } = response;
    if (data.length) {
      if (
        data[0].Type__c === 'Dollar Amount' &&
        typeof data[0].Dollar_Amount__c === 'number'
      ) {
        this.setState({
          discountedTotal: `$${
            selectedProgram.price_int - data[0].Dollar_Amount__c
          }`,
        });
      } else if (
        data[0].Type__c === 'Percentage' &&
        typeof data[0].discount_percentage__c === 'number'
      ) {
        this.setState({
          discountedTotal: `$${
            selectedProgram.price_int -
            (data[0].discount_percentage__c / 100) * selectedProgram.price_int
          }`,
        });
      }
    } else {
      this.setState({
        discountError: true,
      });
    }
  };

  render() {
    let {
      nameOnCard,
      mm,
      yy,
      code,
      onlineExperience,
      onlineConfident,
      onlineEnsureSuccess,
      discountError,
      discountedTotal,
      formattedCardNumber,
    } = this.state;

    let { course, selectedProgram, isPrepProgram } = this.props;
    const isRemote = setIsRemote(this.props.selectedProgram);
    // const isDisabled = setIsDisabled(isRemote, this.props.citizen);

    return (
      <ConfirmationDialog
        handleSubmit={this.handleSubmit}
        render={({ handleOpen }) => {
          return (
            <div>
              <form
                onSubmit={
                  discountedTotal === '$0' ? this.handleSubmit : handleOpen
                }
              >
                <div className="form-section">
                  <div className="form-row">
                    <div
                      className="flex-column form-spacing"
                      ref={this.onlineExperience}
                    >
                      <label>
                        Please rank your level of experience learning in an
                        online environment. <span className="red-star">*</span>
                      </label>
                      <select
                        value={onlineExperience}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.value,
                            'onlineExperience',
                          )
                        }
                        className="input-dropdown"
                      >
                        <option value={0} disabled>
                          Please select....
                        </option>
                        <option value={'1 - Never learned online'}>
                          1 - Never learned online
                        </option>
                        <option value={'2'}>2</option>
                        <option
                          value={'3 - Conducted independent study online'}
                        >
                          3 - Conducted independent study online
                        </option>
                        <option value={'4'}>4</option>
                        <option value={'5 - Taken an online course before'}>
                          5- Taken an online course before
                        </option>
                      </select>
                      <p className="error-message hidden">
                        Online experience is required
                      </p>
                    </div>
                  </div>
                  <div className="form-row">
                    <div
                      className="flex-column form-spacing"
                      ref={this.onlineConfident}
                    >
                      <label>
                        Do you have any questions or concerns about learning in
                        an online environment?{' '}
                        <span className="red-star">*</span>
                      </label>
                      <select
                        value={onlineConfident}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.value,
                            'onlineConfident',
                          )
                        }
                        className="input-dropdown"
                      >
                        <option value={0} disabled>
                          Please select....
                        </option>
                        <option value={true}>1 - Yes</option>
                        <option value={false}>2 - No</option>
                      </select>
                      <p className="error-message hidden">
                        Please indicate whether you have any questions about an
                        online environment
                      </p>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="flex-column form-spacing">
                      <label>
                        Is there anything you’d like Hackbright to know in order
                        to ensure your success learning in an online
                        environment?
                      </label>
                      <textarea
                        type="text"
                        value={onlineEnsureSuccess}
                        onChange={(e) =>
                          handleChange.call(
                            this,
                            e.target.value,
                            'onlineEnsureSuccess',
                          )
                        }
                        className="input-text-large"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-section">
                  <p>Payment Information</p>
                  <p>
                    {isPrepProgram ? 'Prep' : 'Online Python 101'} tuition is{' '}
                    {selectedProgram.price || `$695`}.{' '}
                    {discountedTotal
                      ? `With your discount, the total is ${discountedTotal}.`
                      : ''}{' '}
                    {discountedTotal !== '$0' &&
                      'Hackbright Academy accepts all major credit cards.'}
                  </p>
                  {discountError ? <p>This discount code is invalid</p> : null}
                  {discountedTotal !== '$0' && (
                    <>
                      <div className="form-row">
                        <div
                          className="flex-column form-spacing"
                          ref={this.cardNumberRef}
                        >
                          <label>
                            Card Number <span className="red-star">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="****************"
                            value={formattedCardNumber}
                            autoComplete="cc-number"
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.value,
                                'cardNumber',
                              )
                            }
                            className="input-text-normal"
                          />
                          <p className="error-message hidden">
                            Card number is required
                          </p>
                        </div>
                      </div>
                      <div className="form-row">
                        <div
                          className="flex-column form-spacing"
                          ref={this.nameRef}
                        >
                          <label>
                            Name on Card <span className="red-star">*</span>
                          </label>
                          <input
                            type="text"
                            autoComplete="cc-name"
                            value={nameOnCard}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.value,
                                'nameOnCard',
                              )
                            }
                            className="input-text-normal"
                          />
                          <p className="error-message hidden">
                            Name on card is required
                          </p>
                        </div>
                      </div>
                      <div className="form-row">
                        <div
                          className="flex-column form-spacing"
                          ref={this.mmRef}
                        >
                          <label>
                            MM <span className="red-star">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="MM"
                            value={mm}
                            onChange={(e) =>
                              handleChange.call(this, e.target.value, 'mm')
                            }
                            className="input-text-small"
                          />
                          <p className="error-message hidden">
                            Expiration month is required
                          </p>
                        </div>
                        <div
                          className="flex-column form-spacing"
                          ref={this.yyRef}
                        >
                          <label>
                            YY <span className="red-star">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="YY"
                            value={yy}
                            onChange={(e) =>
                              handleChange.call(this, e.target.value, 'yy')
                            }
                            className="input-text-small"
                          />
                          <p className="error-message hidden">
                            Expiration year is required
                          </p>
                        </div>
                        <div
                          className="flex-column form-spacing"
                          ref={this.codeRef}
                        >
                          <label>
                            Code <span className="red-star">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="***"
                            value={code}
                            autoComplete="cc-csc"
                            onChange={(e) =>
                              handleChange.call(this, e.target.value, 'code')
                            }
                            className="input-text-small"
                          />
                          <p className="error-message hidden">
                            Code is required
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                  {/* <InternationalRemoteWarning
                    isConditional={true}
                    isRemote={isRemote}
                    citizen={this.props.citizen}
                  /> */}
                  {!this.state.cohortSelected && (
                    <NoProgramSelectedWarning
                      updateForm={this.props.updateForm}
                      stateObj={{
                        onlineExperience,
                        onlineConfident,
                        onlineEnsureSuccess,
                      }}
                    />
                  )}
                  <div className="form-row navigation-buttons form-button-row">
                    <button onClick={this.goBack} className="btn-white-red">
                      <span className="float-left">&lt;</span>
                      <span className="centered">Back</span>
                    </button>
                    <button
                      onClick={
                        discountedTotal === '$0'
                          ? this.handleSubmit
                          : handleOpen
                      }
                      className={`btn-red centered${
                        !this.state.cohortSelected ? ' disabled-button' : ''
                      }`}
                      disabled={!this.state.cohortSelected}
                      ref={this.submitRef}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
              <CircleNav
                step="step3"
                switchForm={this.switchForm}
                course={course}
              />
            </div>
          );
        }}
      ></ConfirmationDialog>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps, { updateForm, setSelectedProgram })(
  OnlinePython3,
);
