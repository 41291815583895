import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  handleChange,
  validateEmail,
  applyErrorClasses,
  hideError,
} from '../Utils/jsFunctions';
import axios from 'axios';

export default class ContactModal extends Component {
  constructor() {
    super();
    this.state = {
      first: '',
      last: '',
      email: '',
      interestedIn: '',
      message: '',
      showSuccess: false,
      showFailure: false,
    };
    this.handleChange = handleChange.bind(this);

    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.interestedInRef = React.createRef();
    this.messageRef = React.createRef();
  }

  async sendEmail(e) {
    e.preventDefault();
    if (this.validateInfo()) {
      let { first, last, email, interestedIn, message } = this.state;
      try {
        await axios.post('/api/sendMail', {
          first,
          last,
          email,
          interestedIn,
          message,
        });
        this.setState({
          showSuccess: true,
          showFailure: false,
          first: '',
          last: '',
          email: '',
          interestedIn: '',
          message: '',
        });
      } catch (err) {
        console.log('err in sending message');
        console.log(err);
        this.setState({
          showFailure: true,
          showSuccess: false,
        });
      }
    }
  }

  stopPropagation(e) {
    e.stopPropagation();
  }

  resetForm = () => {
    let { toggleModal } = this.props;
    let { showFailure, showSuccess } = this.state;
    if (showFailure || showSuccess) {
      this.setState({
        showFailure: false,
        showSuccess: false,
      });
    }
    toggleModal();
    this.hideFormErrors();
  };

  hideFormErrors() {
    let refs = [
      { refName: 'firstNameRef' },
      { refName: 'lastNameRef' },
      { refName: 'emailRef' },
      { refName: 'messageRef' },
      { refName: 'interestedInRef' },
    ];
    refs.forEach(refObj => {
      hideError.call(this, refObj.refName);
    });
  }

  validateInfo() {
    //assume info is good
    let passedValidation = true;
    let invalidFields = [];

    let { first, last, email, interestedIn, message } = this.state;

    let refs = [
      { val: first, refName: 'firstNameRef' },
      { val: last, refName: 'lastNameRef' },
      { val: email, refName: 'emailRef' },
      { val: message, refName: 'messageRef' },
    ];

    refs.forEach(refObj => {
      if (!refObj.val) {
        invalidFields.push(refObj.refName);
        passedValidation = false;
      } else {
        hideError.call(this, refObj.refName);
      }
    });

    if (!validateEmail(email)) {
      if (invalidFields.indexOf('emailRef') === -1)
        invalidFields.push('emailRef');
      passedValidation = false;
    } else hideError.call(this, 'emailRef');

    if (!interestedIn.length) {
      if (invalidFields.indexOf('interestedInRef') === -1)
        invalidFields.push('interestedInRef');
      passedValidation = false;
    } else hideError.call(this, 'interestedInRef');

    if (invalidFields.length) applyErrorClasses.call(this, invalidFields);

    return passedValidation;
  }

  render() {
    let { active } = this.props;
    let {
      first,
      last,
      email,
      interestedIn,
      message,
      showSuccess,
      showFailure,
    } = this.state;
    let wrapperClass = active ? 'modal-wrapper' : 'modal-wrapper hidden';
    return (
      <div className={wrapperClass} onClick={this.resetForm}>
        <form
          className="modal-container-main"
          onSubmit={e => this.sendEmail(e)}
          onClick={this.stopPropagation}
        >
          <span className="modal-window-close" onClick={this.resetForm}>
            <FontAwesomeIcon icon={['fa', 'window-close']} />
          </span>
          <div className="modal-header-container">
            <p>CONTACT US</p>
          </div>
          {showFailure ? (
            <div className="error-message">
              Something went wrong on submission. Please check that all the
              fields are filled out correctly and that your email is valid.
            </div>
          ) : null}
          {showSuccess ? (
            <div>
              Thank you for submitting your question. We'll be in touch!
            </div>
          ) : (
            <div className="modal-form">
              <div className="form-row flex-center">
                <div
                  className="flex-column contact-form-spacing"
                  ref={this.firstNameRef}
                >
                  <label>
                    First Name <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    value={first}
                    onChange={e => this.handleChange(e.target.value, 'first')}
                    className="input-text-contact-form"
                  />
                  <p className="error-message hidden">First name is required</p>
                </div>
              </div>
              <div className="form-row flex-center">
                <div
                  className="flex-column contact-form-spacing"
                  ref={this.lastNameRef}
                >
                  <label>
                    Last Name <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    value={last}
                    onChange={e => this.handleChange(e.target.value, 'last')}
                    className="input-text-contact-form"
                  />
                  <p className="error-message hidden">Last name is required</p>
                </div>
              </div>
              <div className="form-row flex-center">
                <div
                  className="flex-column contact-form-spacing"
                  ref={this.emailRef}
                >
                  <label>
                    Email <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={e => this.handleChange(e.target.value, 'email')}
                    className="input-text-contact-form"
                  />
                  <p className="error-message hidden">Email is required</p>
                </div>
              </div>
              <div className="form-row flex-center">
                <div
                  className="flex-column contact-form-spacing"
                  ref={this.interestedInRef}
                >
                  <label>
                    I am interested in <span className="red-star">*</span>
                  </label>
                  <select
                    value={interestedIn}
                    onChange={e =>
                      this.handleChange(e.target.value, 'interestedIn')
                    }
                    className="contact-input-dropdown"
                  >
                    <option value="" defaultValue />
                    <option value="prep">Hackbright Prep</option>
                    <option value="fullTimeSoftware">
                      Full-time Software Engineering Program
                    </option>
                    <option value="partTimeSoftware">
                      Part-time Software Engineering Program
                    </option>
                    <option value="hiringGrads">Hiring Hackbright grads</option>
                    <option value="notSure">Not sure!</option>
                  </select>
                  <p className="error-message hidden">
                    Please select an option
                  </p>
                </div>
              </div>
              <div className="form-row flex-center">
                <div
                  className="flex-column contact-form-spacing"
                  ref={this.messageRef}
                >
                  <label>
                    Message <span className="red-star">*</span>
                  </label>
                  <input
                    type="text"
                    value={message}
                    onChange={e => this.handleChange(e.target.value, 'message')}
                    className="input-text-contact-form"
                  />
                  <p className="error-message hidden">A message is required</p>
                </div>
              </div>
              <div className="form-row flex-center">
                <button className="contact-form-button">SUBMIT</button>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}
