import React from 'react';

export default function FormHeader({ course }) {
  return (
    <>
      <header className="application-heading">
        <h1>NOW YOUR JOURNEY BEGINS</h1>
      </header>
      <aside className="application-sub-heading">
        <p>
          An adventure always begins with a first step, complete this
          application and take that step.
        </p>
        <p>
          Join your community and start you personalized path to a software
          engineering career.
        </p>
      </aside>
    </>
  );
}
