module.exports = {
  concatErrorQueries({ type, message }) {
    let queryString = '?';
    if (type) queryString += `type=${type}&`;
    if (message) queryString += `message=${message}&`;
    queryString =
      queryString.length > 1
        ? queryString.substr(0, queryString.length - 1)
        : '';
    return encodeURI(queryString);
  },
};
