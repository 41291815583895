import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setSelectedProgram } from '../Redux/actions';
import { urlCourseSelector } from '../Utils/jsFunctions';

class CohortDisplay extends Component {
  navigateToApply = () => {
    let { setSelectedProgram, cohort, history } = this.props;
    setSelectedProgram(cohort);
    let courseType = urlCourseSelector(cohort);
    history.push(`/application/step1?course=${courseType}`);
  };
  render() {
    let {
      application_deadline,
      end_date,
      price,
      standard_tuition,
      program_name,
      start_date,
      discounted,
      locationDisplayed,
      discountedTotal,
      time_zone,
      spotsLeft,
    } = this.props.cohort;

    if (discountedTotal || discountedTotal === 0) discounted = true;

    return (
      <div className="cohort-container">
        <h5>{program_name}</h5>
        {discounted ? (
          <p>
            <span className="red-strike-through">
              <span>{standard_tuition}</span>
            </span>
            {discountedTotal || discountedTotal === 0
              ? `$${discountedTotal}`
              : price}
          </p>
        ) : (
          <p>{price}</p>
        )}

        <p>
          <b>
            {start_date
              ? `${start_date} - ${end_date}`
              : 'Start dates to be announced soon '}
          </b>{' '}
        </p>
        <p style={{ visibility: locationDisplayed ? 'visible' : 'hidden' }}>
          {locationDisplayed}
        </p>
        <p style={{ visibility: start_date ? 'visible' : 'hidden' }}>
          {setActionString(program_name)}: {application_deadline}
        </p>
        {time_zone && <p>TimeZone: {time_zone}</p>}
        {spotsLeft <= 5 && spotsLeft > 0 && (
          <p>
            Spot(s) left: <span style={{ color: '#fb3530' }}>{spotsLeft}</span>
          </p>
        )}
        <button className="apply-button" onClick={this.navigateToApply}>
          {setButtonString(program_name)}
        </button>
      </div>
    );
  }
}

function setActionString(courseName) {
  switch (courseName) {
    case 'Software Engineering Part-Time':
    case 'Software Engineering Full-Time':
      return 'Application Deadline';
    case 'Python 101':
    case 'Software Engineering Prep':
      return 'Enrollment Deadline';
    default:
      return 'Application Deadline';
  }
}

function setButtonString(courseName) {
  switch (courseName) {
    case 'Software Engineering Part-Time':
    case 'Software Engineering Full-Time':
      return 'Apply';
    case 'Python 101':
    case 'Software Engineering Prep':
      return 'Enroll';
    default:
      return 'Apply';
  }
}

export default connect(null, { setSelectedProgram })(CohortDisplay);
