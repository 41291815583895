import React from 'react';
import './SubmitError.css';
import { splitQueryString } from '../../Utils/jsFunctions';
import Default from './Copy/Default';
import StripeError from './Copy/StripeError';

export default function SubmitError(props) {
  let queryPairs = '',
    selectedCopy;
  if (props.location.search)
    queryPairs = splitQueryString(decodeURI(props.location.search));
  selectedCopy =
    queryPairs.type &&
    queryPairs.type.toLowerCase().indexOf('stripe') !== -1 ? (
      <StripeError {...queryPairs} {...props} />
    ) : (
      <Default {...props} />
    );
  return selectedCopy;
}
