import React from 'react';
import './SubmitSuccess.css';
import { deleteCookie } from '../../Utils/jsFunctions';

export default function SubmitSuccess() {
  deleteCookie('dc');
  return (
    <section className="view-page-container">
      <div className="view-page-wrapper flex-column">
        <span className="page-heading">Hackbright Academy Application</span>
        <span className="page-sub-heading">
          Thank you for your application!
        </span>
        <p className="info-text">
          Thank you for submitting your application to Hackbright Academy, and
          congratulations! You are now one step closer to your goal of becoming
          a software engineer.{' '}
          <span className="info-text">
            In the next 24 hours, you will receive additional information
            outlining the next steps in the onboarding process.
          </span>
          <br />
          <br />
          If you have any questions, let us know at
          admissions@hackbrightacademy.com or give us a call at (415) 862-0595.
          <br />
        </p>
        <i className="italic-mont">Admissions Team</i>
        <span className="bolded-text">Hackbright Academy</span>
        <span className="bolded-text">#CHANGETHERATIO</span>
      </div>
    </section>
  );
}
