import React from 'react';
import queryString from 'query-string';

//Program types
import OnlinePython3 from './programType/OnlinePython3';
import Software3 from './programType/Software3';
import FormHeader from '../../Components/FormHeader';

export default function ApplicationStep3(props) {
  let { course } = queryString.parse(props.location.search);
  if (!course) course = 'software-engineering';
  return (
    <section className="view-page-container">
      <FormHeader course={course} />
      <div className="view-page-wrapper">
        {course === 'software-engineering' ? (
          <Software3 history={props.history} course={course} />
        ) : (
          <OnlinePython3 history={props.history} course={course} />
        )}
      </div>
    </section>
  );
}
