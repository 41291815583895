import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationDialog({ handleSubmit, render }) {
  const [open, setOpen] = useState(false);

  function handleOpen(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setOpen(true);
  }

  function handleClose(value) {
    setOpen(false);
    if (handleSubmit) handleSubmit(null, value);
  }

  return (
    <>
      {render({
        handleOpen,
      })}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Deposit Confirmation'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          I understand that the $249 registration fee is non-refundable. Should I withdraw from class, the $249 registration fee will be withheld from all refunds.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('cancel')} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleClose('submit')}
            color="primary"
            value="acknowledge"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
