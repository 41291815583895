import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Footer(props) {
  return (
    <div className="footer-main-container">
      {/* <div className="links-container">
                <div className="link-section">
                    <span>HELPFUL LINKS</span>
                    <a href="https://hackbrightacademy.com/phenomenal-woman-ebook/">How to Become an Engineer</a>
                    <a href="https://hackbrightacademy.com/faq/">FAQ</a>
                    <a href="https://hackbrightacademy.com/scholarships/">Scholarships</a>
                    <a href="https://hackbrightacademy.com/payment-plans/">Payment Plans</a>
                    <a href="https://hackbrightacademy.com/hackbright-blog/">Blog</a>
                </div>
                <div className="link-section">
                    <span>JOIN</span>
                    <a href="https://hackbrightacademy.com/upcoming-cohorts/">Upcoming Cohorts</a>
                    <a href="https://hackbrightacademy.com/events/">Events</a>
                    <a href="https://hackbrightacademy.com/recruit-from-hackbright/">Recruit from Hackbright</a>
                    <a href="https://hackbrightacademy.com/mentor-at-hackbright/">Mentor a Student</a>
                    <a href="https://hackbrightacademy.com/referrals/">Refer a Future Engineer</a>
                </div>
                <div className="link-section">
                    <span>ABOUT</span>
                    <a href="https://hackbrightacademy.com/mission/">Mission</a>
                    <a href="https://hackbrightacademy.com/your-hackbright-team/">Team</a>
                    <a href="https://hackbrightacademy.com/careers/">Careers</a>
                    <a href="https://hackbrightacademy.com/contact-us/">Contact</a>
                    <a href="https://hackbrightacademy.com/the-education-team/">Education Team</a>
                </div>
                <div className="link-section">
                    <span>MORE</span>
                    <a href="https://hackbrightacademy.com/media-center/">Media Center</a>
                    <a href="https://hackbrightacademy.com/press/">Press</a>
                    <a href="https://hackbrightacademy.com/regulatory-information/">Regulatory Information</a>
                </div>
            </div> */}
      <div className="footer-width-container">
        <div className="legal-section">
          <a href="https://hackbrightacademy.com/privacy-policy/">
            Privacy Policy
          </a>
          <a href="https://hackbrightacademy.com/regulatory-information/">
            Regulatory Information
          </a>
        </div>
        <div className="social-section">
          <span className="social-bubble" onClick={props.toggleModal}>
            <FontAwesomeIcon icon={['fa', 'envelope-square']} />
          </span>
          {/* <a
            href="https://www.facebook.com/HackbrightAcademy"
            className="social-bubble"
          >
            <FontAwesomeIcon icon={['fab', 'facebook-f']} />
          </a> */}
          <a href="https://twitter.com/hackbright" className="social-bubble">
            <FontAwesomeIcon icon={['fab', 'twitter']} />
          </a>
          {/* <a
            href="https://www.instagram.com/hackbright/"
            className="social-bubble"
          >
            <FontAwesomeIcon icon={['fab', 'instagram']} />
          </a> */}
          <a
            href="https://www.youtube.com/channel/UC3zTsxP0wHeb1ZxA2FV449g"
            className="social-bubble"
          >
            <FontAwesomeIcon icon="play" />
          </a>
        </div>
      </div>
    </div>
  );
}
