import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      mobileSideMenuActive: false,
      educationListItems: [
        {
          name: 'ONLINE PYTHON 101 (NEW!)',
          link: 'https://hackbrightacademy.com/online-python101/',
        },
        {
          name: 'SOFTWARE ENGINEERING PROGRAM',
          link: 'https://hackbrightacademy.com/software-engineering-program/',
        },
        {
          name: 'PREP COURSE',
          link: 'https://hackbrightacademy.com/prep/',
        },
        {
          name: 'OUR TECH STACK',
          link:
            'https://hackbrightacademy.com/full-stack-development-hackbright-academy/',
        },
        {
          name: 'EXPLORE PYTHON',
          link: 'https://hackbrightacademy.com/online/',
        },
      ],
      communityListItems: [
        {
          name: 'CAREER DEVELOPMENT',
          link: 'https://hackbrightacademy.com/career-development/',
        },
        {
          name: 'PARTNER COMPANIES',
          link: 'https://hackbrightacademy.com/partner-network/',
        },
        {
          name: 'ALUMNAE NETWORK',
          link: 'https://hackbrightacademy.com/alumnae-network/',
        },
        {
          name: 'MENTOR A STUDENT',
          link: 'https://hackbrightacademy.com/mentor-at-hackbright/',
        },
      ],
      employersListItems: [
        {
          name: 'RECRUIT FROM HACKBRIGHT',
          link: 'https://hackbrightacademy.com/recruit-from-hackbright/',
        },
        {
          name: 'PARTNER WITH US',
          link: 'https://hackbrightacademy.com/partner-with-hackbright/',
        },
        {
          name: 'ATTEND AN EVENT',
          link: 'https://hackbrightacademy.com/partner-events/',
        },
        {
          name: 'HOST AN EVENT',
          link: 'https://hackbrightacademy.com/event-venue-sign/',
        },
      ],
    };
  }

  showMobileSideMenu = () => {
    this.setState({
      mobileSideMenuActive: !this.state.mobileSideMenuActive,
    });
  };

  render() {
    return (
      <div className="header-main-container">
        <a href="https://hackbrightacademy.com/" className="logo-container">
          <img
            src="https://hackbrightacademy.com/content/uploads/2017/08/HB_new_logo-2@2x.png"
            alt="HackBright Logo"
          />
        </a>
        <span className="nav-items-container">
          <Link to="/">
            <button className="hacklink-button pointer">BACK</button>
          </Link>
        </span>
      </div>
    );
  }
}
