import React from 'react';
import { useHistory } from 'react-router-dom';

const NoProgramSelectedWarning = ({ updateForm, stateObj }) => {
  const history = useHistory();
  return (
    <div
      style={{
        padding: '2rem 1rem',
        margin: '2rem 0',
        border: '1px solid #FF3235',
      }}
    >
      <p>
        You haven't selected a cohort. Please{' '}
        <span
          className="link-with-action"
          onClick={() => {
            // handle updating state
            if (updateForm && stateObj) updateForm(stateObj);
            history.push('/');
          }}
        >
          select a cohort
        </span>{' '}
        to continue with your application.
      </p>
    </div>
  );
};

export default NoProgramSelectedWarning;
