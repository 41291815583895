import React, { Component } from 'react';
import './App.css';
import { Switch, Route, withRouter } from 'react-router-dom';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import { connect } from 'react-redux';
import {
  splitQueryString,
  parseUrl,
  checkDiscountCode,
  setDiscountedTotal,
  setUtmParamsInLocalStorage,
} from './Utils/jsFunctions';

//Redux
import {
  setCohorts,
  setSelectedProgram,
  setDiscountCode,
} from './Redux/actions';

//Components
import Header from './Components/Header';
import Footer from './Components/Footer';
import ContactModal from './Components/ContactModal';

//Views
import ApplyPage from './Views/ApplyPage/ApplyPage';
import ApplicationStep1 from './Views/ApplicationStep1/ApplicationStep1';
import ApplicationStep2 from './Views/ApplicationStep2/ApplicationStep2';
import ApplicationStep3 from './Views/ApplicationStep3/ApplicationStep3';
import SubmitSuccess from './Views/SubmitSuccess/SubmitSuccess';
import SubmitError from './Views/SubmitError/SubmitError';

// this value is not secret but should probably be held in an environment variable.
// keeping it here to simplify deployment
const SENTRY_DSN = 'https://5d728ac1ce1b4ef49c78083d19b2e9df@sentry.devmountain.com/12';

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 0,
})
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactModalActive: false,
      linkMeta: parseUrl.call(this),
      showNoMatch: false,
    };
  }

  async componentDidMount() {
    const { linkMeta } = this.state;
    const params = new URLSearchParams(window.location.search);
    let discountCodeParam = params.get('dc');

    if (!discountCodeParam) {
      // Check if cookie exists
      discountCodeParam = document.cookie
        .split('; ')
        .find((row) => row.startsWith('dc='));
      discountCodeParam = discountCodeParam
        ? discountCodeParam.split('=')[1]
        : '';
    } else {
      // Set cookie to expire after 2 days
      document.cookie = `dc=${discountCodeParam};max-age=${
        2 * 24 * 60 * 60 * 1000
      }`;
    }

    if (discountCodeParam) {
      axios.post('/api/discount-code', {
        dc: discountCodeParam,
      });
      // validate code to show discounted prices on front page
      const response = await checkDiscountCode(discountCodeParam);

      this.props.setDiscountCode(
        discountCodeParam,
        !!response.data.length,
        response.data.length ? response.data[0] : {},
      );
    }

    let {
      cohorts,
      setCohorts,
      location,
      history,
      setSelectedProgram,
      discountCode,
      discountCodeMeta,
    } = this.props;

    if (!cohorts.length) {
      try {
        let { data } = await axios.get('/api/cohorts');
        // Add discounted total to each cohort if applicable to skip unnecessary second validation
        if (discountCode && discountCodeMeta && discountCodeMeta.validated) {
          data = data.map((cohort) => {
            if (
              !Object.keys(discountCodeMeta.programAssociations).length ||
              discountCodeMeta.programAssociations[cohort.program_identifier]
            ) {
              cohort.discountedTotal = setDiscountedTotal({
                discountType: discountCodeMeta.type,
                discountPercentage: discountCodeMeta.discountPercentage,
                discountDollarAmount: discountCodeMeta.discountDollarAmount,
                useAdvertisedRate: discountCodeMeta.useAdvertisedRate,
                currentPrice: cohort.price_int,
                standardTuition: cohort.standard_tuition_int,
              });
            }
            return cohort;
          });
        }

        setCohorts(data);

        if (
          location.search &&
          location.search.toLowerCase().indexOf('target_promotion=1') !== -1
        ) {
          if (location.search.toLowerCase().indexOf('sender=') !== -1) {
            let { sender } = splitQueryString(location.search);
            axios.post('/api/sender', { sender });
          }
          setSelectedProgram(data.find((c) => Number(c.cohort_id) === 31));
          history.push('/application/step1?course=software-engineering');
        }
        // In case of direct link
        if (linkMeta.isDirectLink && linkMeta.cohortName) {
          const matchingCohort = data.find(
            (c) =>
              c.cohort_name.toLowerCase() === linkMeta.cohortName.toLowerCase(),
          );
          if (matchingCohort) {
            setSelectedProgram(matchingCohort);
          }
        }
      } catch (err) {
        console.log(err);
        alert('Could not retrieve cohorts due to a server error.');
      } finally {
        this.setState({ showNoMatch: true });
      }
    }

    // capture utm params in localStorage so we dont lose them on navigation
    setUtmParamsInLocalStorage();
  }

  componentDidUpdate() {
    // capture utm params in localStorage so we dont lose them on navigation
    setUtmParamsInLocalStorage();
  }

  toggleModal = () => {
    this.setState({
      contactModalActive: !this.state.contactModalActive,
    });
  };

  render() {
    return (
      <div>
        <Header />
        <ContactModal
          active={this.state.contactModalActive}
          toggleModal={this.toggleModal}
        />
        <div className="page-wrapper">
          <Switch>
            <Route path="/" exact component={ApplyPage} />
            <Route
              path="/application/step1"
              render={(props) => {
                return (
                  <ApplicationStep1
                    {...props}
                    showNoMatch={this.state.showNoMatch}
                  />
                );
              }}
            />
            <Route path="/application/step2" component={ApplicationStep2} />
            <Route path="/application/step3" component={ApplicationStep3} />
            <Route path="/submission/success" component={SubmitSuccess} />
            <Route path="/submission/error" component={SubmitError} />
          </Switch>
        </div>
        <Footer toggleModal={this.toggleModal} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cohorts: state.cohorts,
    discountCode: state.discountCode,
    discountCodeMeta: state.discountCodeMeta,
  };
};

export default withRouter(
  connect(mapStateToProps, { setCohorts, setSelectedProgram, setDiscountCode })(
    App,
  ),
);
