import React from 'react';

export default function SubmitError({ message, history }) {
  return (
    <section className="view-page-container">
      <div className="view-page-wrapper flex-column">
        <span className="page-heading">Hackbright Academy Application</span>
        <p className="info-text">
          There was a problem processing your payment.
          <br />
          <br />
          {message}
        </p>
        <p className="info-text">
          Your application has been submitted. Please{' '}
          <span
            className="red-underline info-text pointer"
            onClick={() => history.go(-1)}
          >
            click here
          </span>{' '}
          to go back and change your credit card details. If you reach this
          error message again please reach out to the Admissions office at
          admissions@hackbrightacademy.com or call us at (415) 862-0595.
        </p>
        <i className="italic-mont">Admissions Team</i>
        <span className="bolded-text">Hackbright Academy</span>
        <span className="bolded-text">#CHANGETHERATIO</span>
      </div>
    </section>
  );
}
