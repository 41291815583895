import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {
  handleChange,
  applyErrorClasses,
  hideError,
  switchForm,
} from '../../Utils/jsFunctions';
import CircleNav from '../../Components/CircleNav';

//Redux
import { updateForm, setSelectedProgram } from '../../Redux/actions';
import axios from 'axios';
import FormHeader from '../../Components/FormHeader';

class ApplicationStep2 extends Component {
  constructor(props) {
    super(props);
    let {
      linkedin,
      github,
      otherLinks,
      resumeFile,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      hearAbout,
      applyingSoftwareProgram,
    } = this.props;
    let previousFileLocation;
    previousFileLocation = resumeFile.name ? resumeFile.name : null;

    this.state = {
      linkedin,
      github,
      otherLinks,
      resumeFile,
      previousFileLocation,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      hearAbout,
      applyingSoftwareProgram,
    };

    //refs for error handling
    this.linkedinRef = React.createRef();
    this.educationRef = React.createRef();
    this.nameOfSchoolRef = React.createRef();
    this.majorRef = React.createRef();
    this.experienceRef = React.createRef();
    this.interestedInApplyingRef = React.createRef();
    this.hearAboutRef = React.createRef();

    //bind util functions
    this.switchForm = switchForm.bind(this);
  }

  handleNext = (e) => {
    e.preventDefault();
    let { course } = queryString.parse(this.props.location.search);
    if (!course) course = 'software-engineering';
    let { updateForm } = this.props;
    //if this passes, save info to redux for smart form validation
    if (this.validateInfo()) {
      updateForm(this.state);
      this.props.history.push(`/application/step3?course=${course}`);
    } else {
      document.body.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  handleBack = (e) => {
    e.preventDefault();
    this.props.updateForm(this.state);
    let { history } = this.props;
    let { course } = queryString.parse(this.props.location.search);
    history.push(`/application/step1?course=${course}`);
  };

  validateInfo() {
    //assume info is good
    let passedValidation = true;
    let invalidFields = [];
    let { course } = queryString.parse(this.props.location.search);

    let {
      // linkedin,
      education,
      experience,
      hearAbout,
      applyingSoftwareProgram,
      nameOfSchool,
      major,
    } = this.state;
    let { selectedProgram } = this.props;

    education = education === 'please...' ? '' : education;

    let refs = [
      // linkedin is no longer actually required,
      // but we do want it to appear as though it is
      // { val: linkedin, refName: 'linkedinRef' },
      { val: education, refName: 'educationRef' },
      { val: experience, refName: 'experienceRef' },
    ];

    if (course === 'online-python') {
      refs.push({ val: hearAbout.touched, refName: 'hearAboutRef' });
    }

    if (
      course === 'online-python' ||
      selectedProgram.program_identifier === 'software-engineering-prep'
    ) {
      refs.push({
        val: applyingSoftwareProgram.touched,
        refName: 'interestedInApplyingRef',
      });
    }

    refs.forEach((refObj) => {
      if (!refObj.val) {
        invalidFields.push(refObj.refName);
        passedValidation = false;
      } else {
        hideError.call(this, refObj.refName);
      }
    });

    if (education === 'highschool') {
      if (!nameOfSchool) {
        if (invalidFields.indexOf('nameOfSchoolRef') === -1)
          invalidFields.push('nameOfSchoolRef');
        passedValidation = false;
      } else hideError.call(this, 'nameOfSchoolRef');
    }

    if (education === 'bachelors' || education === 'masters+') {
      if (!nameOfSchool) {
        if (invalidFields.indexOf('nameOfSchoolRef') === -1)
          invalidFields.push('nameOfSchoolRef');
        passedValidation = false;
      } else hideError.call(this, 'nameOfSchoolRef');

      if (!major) {
        if (invalidFields.indexOf('majorRef') === -1)
          invalidFields.push('majorRef');
        passedValidation = false;
      } else hideError.call(this, 'majorRef');
    }

    if (invalidFields.length) applyErrorClasses.call(this, invalidFields);

    return passedValidation;
  }

  handleResumeChange = (file) => {
    this.setState(
      {
        resumeFile: file,
      },
      () => {
        this.uploadFile();
      },
    );
  };

  uploadFile = async () => {
    let { resumeFile } = this.state;
    let { updateForm } = this.props;
    let formData = new FormData();
    formData.append('file', resumeFile);
    const { data } = await axios.post('/api/fileUpload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (data.serverResumePath)
      updateForm({ serverResumePath: data.serverResumePath });
  };

  render() {
    let {
      linkedin,
      github,
      otherLinks,
      education,
      nameOfSchool,
      major,
      certificates,
      experience,
      hearAbout,
      previousFileLocation,
      applyingSoftwareProgram,
    } = this.state;

    let { selectedProgram } = this.props;

    let schoolName, majors;
    let { course } = queryString.parse(this.props.location.search);
    if (!course) course = 'software-engineering';

    if (education === 'highschool') {
      schoolName = (
        <div className="flex-column form-spacing" ref={this.nameOfSchoolRef}>
          <label>
            Name of High School <span className="red-star">*</span>
          </label>
          <input
            type="text"
            value={nameOfSchool}
            onChange={(e) =>
              handleChange.call(this, e.target.value, 'nameOfSchool')
            }
            className="input-text-normal"
          />
          <p className="error-message hidden">Name of school is required</p>
        </div>
      );
    } else if (education === 'bachelors') {
      schoolName = (
        <div className="flex-column form-spacing" ref={this.nameOfSchoolRef}>
          <label>
            Name of Undergraduate University <span className="red-star">*</span>
          </label>
          <input
            type="text"
            value={nameOfSchool}
            onChange={(e) =>
              handleChange.call(this, e.target.value, 'nameOfSchool')
            }
            className="input-text-normal"
          />
          <p className="error-message hidden">Name of school is required</p>
        </div>
      );
      majors = (
        <div className="flex-column form-spacing" ref={this.majorRef}>
          <label>
            Major(s) <span className="red-star">*</span>
          </label>
          <input
            type="text"
            value={major}
            onChange={(e) => handleChange.call(this, e.target.value, 'major')}
            className="input-text-normal"
          />
          <p className="error-message hidden">Major is required</p>
        </div>
      );
    } else if (education === 'masters+') {
      schoolName = (
        <div className="flex-column form-spacing" ref={this.nameOfSchoolRef}>
          <label>
            Name of Masters/PhD University <span className="red-star">*</span>
          </label>
          <input
            type="text"
            value={nameOfSchool}
            onChange={(e) =>
              handleChange.call(this, e.target.value, 'nameOfSchool')
            }
            className="input-text-normal"
          />
          <p className="error-message hidden">Name of school is required</p>
        </div>
      );
      majors = (
        <div className="flex-column form-spacing" ref={this.majorRef}>
          <label>
            Major(s) <span className="red-star">*</span>
          </label>
          <input
            type="text"
            value={major}
            onChange={(e) => handleChange.call(this, e.target.value, 'major')}
            className="input-text-normal"
          />
          <p className="error-message hidden">Major is required</p>
        </div>
      );
    }

    return (
      <section className="view-page-container">
        <FormHeader course={course} />
        <div className="view-page-wrapper">
          <div>
            <div className="heading-section"></div>
            <form onSubmit={this.handleNext}>
              {/* Form Section */}
              <div className="form-section">
                <p>Professional Experience</p>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.linkedinRef}
                  >
                    <label>
                      LinkedIn URL (required) <span className="red-star">*</span>
                    </label>
                    <input
                      type="text"
                      value={linkedin}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'linkedin')
                      }
                      className="input-text-normal"
                    />
                    <p className="error-message hidden">LinkedIn is required</p>
                  </div>
                  <div className="flex-column form-spacing">
                    <label>Github URL</label>
                    <input
                      type="text"
                      value={github}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'github')
                      }
                      className="input-text-normal"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="flex-column form-spacing">
                    <label>
                      Other links you would like to share? (Examples: website,
                      blog, Tumblr, and other projects?)
                    </label>
                    <textarea
                      type="text"
                      value={otherLinks}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'otherLinks')
                      }
                      className="input-text-large"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="flex-column form-spacing">
                    <label>Resumes are strongly encouraged. If you can&apos;t attach one now, you can email one to {' '}
                      <a href="mailto: admissions@hackbrightacademy.com">admissions@hackbrightacademy.com</a></label>
                    <input
                      type="file"
                      name="resume-upload"
                      onChange={(e) =>
                        this.handleResumeChange(e.target.files[0], 'resumeFile')
                      }
                    />
                  </div>
                  {previousFileLocation ? (
                    <div className="flex-column form-spacing">
                      <label>Previous File</label>
                      <input
                        type="text"
                        value={previousFileLocation}
                        disabled
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Form section */}
              {selectedProgram.program_identifier !==
                'software-engineering-prep' &&
              selectedProgram.program_identifier !== 'python-101' ? null : (
                <div className="form-section">
                  <div className="form-row">
                    <div
                      className="flex-column form-spacing"
                      ref={this.interestedInApplyingRef}
                    >
                      <label>
                        Are you interested in applying to the Software
                        Engineering Program upon completion of this course?{' '}
                        <span className="red-star">*</span>
                      </label>
                      <span>
                        <input
                          type="checkbox"
                          checked={
                            applyingSoftwareProgram.touched
                              ? applyingSoftwareProgram.status
                              : false
                          }
                          onChange={(e) =>
                            handleChange.call(
                              this,
                              true,
                              'applyingSoftwareProgram.status',
                            )
                          }
                          name="applyingSoftwareProgram.true"
                        />
                        <label htmlFor="applyingSoftwareProgram.true">
                          Yes
                        </label>
                      </span>
                      <span>
                        <input
                          type="checkbox"
                          checked={
                            applyingSoftwareProgram.touched
                              ? !applyingSoftwareProgram.status
                              : false
                          }
                          onChange={(e) =>
                            handleChange.call(
                              this,
                              false,
                              'applyingSoftwareProgram.status',
                            )
                          }
                          name="applyingSoftwareProgram.false"
                        />
                        <label htmlFor="applyingSoftwareProgram.false">
                          No
                        </label>
                      </span>
                      <p className="error-message hidden">Indicate yes or no</p>
                    </div>
                  </div>
                  {selectedProgram.program_identifier === 'python-101' ? (
                    <div className="form-row">
                      <div
                        className="flex-column form-spacing"
                        ref={this.hearAboutRef}
                      >
                        <label>
                          How did you hear about Hackbright (please select all
                          that apply)? <span className="red-star">*</span>
                        </label>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.currentStudent}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.currentStudent',
                              )
                            }
                            name="hearAbout.currentStudent"
                          />
                          <label htmlFor="hearAbout.currentStudent">
                            Current Student
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.alumna}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.alumna',
                              )
                            }
                            name="hearAbout.alumna"
                          />
                          <label htmlFor="hearAbout.alumna">Alumna</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.instructor}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.instructor',
                              )
                            }
                            name="hearAbout.instructor"
                          />{' '}
                          <label htmlFor="hearAbout.instructor">
                            Instructor/Staff Member
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.womenCode}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.womenCode',
                              )
                            }
                            name="hearAbout.womenCode"
                          />
                          <label htmlFor="hearAbout.womenCode">
                            Women Who Code
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.google}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.google',
                              )
                            }
                            name="hearAbout.google"
                          />
                          <label htmlFor="hearAbout.google">
                            Google Search
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.articleBlog}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.articleBlog',
                              )
                            }
                            name="hearAbout.articleBlog"
                          />{' '}
                          <label htmlFor="hearAbout.articleBlog">
                            Article/Blog
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.event}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.event',
                              )
                            }
                            name="hearAbout.event"
                          />
                          <label htmlFor="hearAbout.event">Event</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.advertisement}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.advertisement',
                              )
                            }
                            name="hearAbout.advertisement"
                          />
                          <label htmlFor="hearAbout.advertisement">
                            Advertisement
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.facebook}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.facebook',
                              )
                            }
                            name="hearAbout.facebook"
                          />
                          <label htmlFor="hearAbout.facebook">
                            Facebook/Twitter
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.linkedin}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.linkedin',
                              )
                            }
                            name="hearAbout.linkedin"
                          />
                          <label htmlFor="hearAbout.linkedin">LinkedIn</label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.currentEmployer}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.currentEmployer',
                              )
                            }
                            name="hearAbout.currentEmployer"
                          />
                          <label htmlFor="hearAbout.currentEmployer">
                            Current/Previous Employer
                          </label>
                        </span>
                        <span>
                          <input
                            type="checkbox"
                            checked={hearAbout.other}
                            onChange={(e) =>
                              handleChange.call(
                                this,
                                e.target.checked,
                                'hearAbout.other',
                              )
                            }
                            name="hearAbout.other"
                          />
                          <label htmlFor="hearAbout.other">Other</label>
                        </span>
                        <p className="error-message hidden">
                          Choose at least one value
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
              {/* Form Section */}
              <div className="form-section">
                <p>Education</p>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.educationRef}
                  >
                    <label>
                      Highest level of education completed?{' '}
                      <span className="red-star">*</span>
                    </label>
                    <select
                      value={education}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'education')
                      }
                      className="input-dropdown"
                    >
                      <option value="please..." disabled>
                        Please select....
                      </option>
                      <option value="highschool">
                        High School degree or equivalency
                      </option>
                      <option value="bachelors">Bachelors Degree</option>
                      <option value="masters+">Masters Degree</option>
                    </select>
                    <p className="error-message hidden">
                      Choose highest level of education
                    </p>
                  </div>
                  {schoolName}
                  {majors}
                </div>
                <div className="form-row">
                  <div className="flex-column form-spacing">
                    <label>
                      Additional certificates or education you would like to
                      share with us?
                    </label>
                    <textarea
                      type="text"
                      value={certificates}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'certificates')
                      }
                      className="input-text-large"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div
                    className="flex-column form-spacing"
                    ref={this.experienceRef}
                  >
                    <label>
                      Please provide a brief description of your programming
                      experience? <span className="red-star">*</span>
                    </label>
                    <textarea
                      type="text"
                      value={experience}
                      onChange={(e) =>
                        handleChange.call(this, e.target.value, 'experience')
                      }
                      className="input-text-large"
                    />
                    <p className="error-message hidden">
                      Experience is required
                    </p>
                  </div>
                </div>
                <div className="form-row navigation-buttons form-button-row">
                  <button onClick={this.handleBack} className="btn-white-red">
                    <span className="float-left">&lt;</span>
                    <span className="centered">Back</span>
                  </button>
                  <button
                    onClick={this.handleNext}
                    className="btn-red centered"
                  >
                    <span className="centered">Next</span>
                    <span className="float-right">></span>
                  </button>
                </div>
              </div>
            </form>
            <CircleNav
              step="step2"
              switchForm={this.switchForm}
              course={course}
            />
          </div>
        </div>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    linkedin: state.linkedin,
    github: state.github,
    otherLinks: state.otherLinks,
    resumeFile: state.resumeFile,
    education: state.education,
    nameOfSchool: state.nameOfSchool,
    major: state.major,
    certificates: state.certificates,
    experience: state.experience,
    hearAbout: state.hearAbout,
    applyingSoftwareProgram: state.applyingSoftwareProgram,
    selectedProgram: state.selectedProgram,
  };
}

export default connect(mapStateToProps, { updateForm, setSelectedProgram })(
  ApplicationStep2,
);
