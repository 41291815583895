import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import ScrollToTop from './Components/ScrollToTop'
import * as serviceWorker from './serviceWorker';

import {BrowserRouter} from 'react-router-dom'

import { Provider } from 'react-redux'
import store from './Redux/store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faEnvelopeSquare, faPlay, faArrowDown, faArrowUp, faBars, faWindowClose, faPlus, faMinus, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

library.add(faEnvelopeSquare)
library.add(faPlay)
library.add(faFacebookF)
library.add(faTwitter)
library.add(faInstagram)
library.add(faArrowDown)
library.add(faArrowUp)
library.add(faBars)
library.add(faWindowClose)
library.add(faPlus)
library.add(faMinus)
library.add(faArrowLeft)

ReactDOM.render(
<Provider store={store}>
    <BrowserRouter>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
